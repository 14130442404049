import React from "react";
import "./style.scss";

const ContactArea = () => {
  return (
    <div className="contact">
      <h1>İLETİŞİM BİLGİLERİMİZ</h1>
      <div className="contact-area">
        <h1>FİRMA YETKİLİLERİ</h1>
        <div className="expert-area">
          <div className="first-expert">
            <span>Berkant Tufan</span>
            <span>
              <i className="fa-solid fa-phone"></i>
              <a href="tel:+90543 270 24 66"> +90(543) 270 24 66</a>
            </span>
            <span>
              <i className="fa-solid fa-envelope"></i>
              <a href="mailto:berkant@betarobotik.com">
                berkant@betarobotik.com
              </a>
            </span>
          </div>
          <div className="second-expert">
            <span>Engin Aktaş</span>
            <span>
              <i className="fa-solid fa-phone"></i>
              <a href="tel:+90535 357 18 97"> +90(535) 357 18 97</a>
            </span>
            <span>
              <i className="fa-solid fa-envelope"></i>
              <a href="mailto:engin@betarobotik.com"> engin@betarobotik.com</a>
            </span>
          </div>
        </div>
      </div>
      <div className="location-area">
        <h1>ADRESLER</h1>
        <div className="expert-area">
          <div className="first-expert">
            <span>Ofis Adresi</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31.453"
                height="31.453"
                viewBox="0 0 31.453 31.453"
              >
                <path
                  d="M5.82,18.2a1.843,1.843,0,1,0,2.674,2.2h4.774a1.843,1.843,0,0,1,0,3.686H5.9a3.072,3.072,0,1,0,0,6.143h6.143a.614.614,0,0,0,0-1.229H5.9a1.843,1.843,0,1,1,0-3.686h7.372a3.072,3.072,0,0,0,0-6.143H8.495a1.852,1.852,0,0,0-.8-.972l4.692-7.7a6.758,6.758,0,1,0-11.258,0Zm.937,2.2a.614.614,0,1,1,.614-.614A.615.615,0,0,1,6.758,20.4Zm0-19.167a5.529,5.529,0,0,1,4.6,8.6l-.014.022L6.758,17.372l-4.6-7.547a5.529,5.529,0,0,1,4.6-8.6Z"
                  fill="#000"
                ></path>
                <path
                  d="M63.072,66.143A3.072,3.072,0,1,0,60,63.072,3.075,3.075,0,0,0,63.072,66.143Zm0-4.915a1.843,1.843,0,1,1-1.843,1.843A1.845,1.845,0,0,1,63.072,61.229Z"
                  transform="translate(-56.314 -56.314)"
                  fill="#000"
                ></path>
                <path
                  d="M283.74,166a6.758,6.758,0,0,0-5.626,10.5l4.675,7.333a1.853,1.853,0,0,0-.786.964h-5.389a.614.614,0,1,0,0,1.229H282a1.843,1.843,0,1,0,2.689-2.192l4.675-7.333A6.758,6.758,0,0,0,283.74,166Zm0,20.027a.614.614,0,1,1,.614-.614A.615.615,0,0,1,283.74,186.027Zm4.6-10.2-4.6,7.216-4.6-7.216a5.529,5.529,0,1,1,9.2,0Z"
                  transform="translate(-259.045 -155.802)"
                  fill="#000"
                ></path>
                <path
                  d="M355.072,226a3.072,3.072,0,1,0,3.072,3.072A3.075,3.075,0,0,0,355.072,226Zm0,4.915a1.843,1.843,0,1,1,1.843-1.843A1.845,1.845,0,0,1,355.072,230.915Z"
                  transform="translate(-330.376 -212.116)"
                  fill="#000"
                ></path>
                <ellipse
                  cx="1"
                  cy="0.5"
                  rx="1"
                  ry="0.5"
                  transform="translate(13.727 28.727)"
                ></ellipse>
              </svg>
              <p>
                Emek Mahallesi, Ordu Caddesi, Sinpaş Aqua City No:39
                <span>Sancaktepe/İstanbul</span>
              </p>
            </span>
          </div>
          <div className="second-expert">
            <span>Depo Adresi</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31.453"
                height="31.453"
                viewBox="0 0 31.453 31.453"
              >
                <path
                  d="M5.82,18.2a1.843,1.843,0,1,0,2.674,2.2h4.774a1.843,1.843,0,0,1,0,3.686H5.9a3.072,3.072,0,1,0,0,6.143h6.143a.614.614,0,0,0,0-1.229H5.9a1.843,1.843,0,1,1,0-3.686h7.372a3.072,3.072,0,0,0,0-6.143H8.495a1.852,1.852,0,0,0-.8-.972l4.692-7.7a6.758,6.758,0,1,0-11.258,0Zm.937,2.2a.614.614,0,1,1,.614-.614A.615.615,0,0,1,6.758,20.4Zm0-19.167a5.529,5.529,0,0,1,4.6,8.6l-.014.022L6.758,17.372l-4.6-7.547a5.529,5.529,0,0,1,4.6-8.6Z"
                  fill="#000"
                ></path>
                <path
                  d="M63.072,66.143A3.072,3.072,0,1,0,60,63.072,3.075,3.075,0,0,0,63.072,66.143Zm0-4.915a1.843,1.843,0,1,1-1.843,1.843A1.845,1.845,0,0,1,63.072,61.229Z"
                  transform="translate(-56.314 -56.314)"
                  fill="#000"
                ></path>
                <path
                  d="M283.74,166a6.758,6.758,0,0,0-5.626,10.5l4.675,7.333a1.853,1.853,0,0,0-.786.964h-5.389a.614.614,0,1,0,0,1.229H282a1.843,1.843,0,1,0,2.689-2.192l4.675-7.333A6.758,6.758,0,0,0,283.74,166Zm0,20.027a.614.614,0,1,1,.614-.614A.615.615,0,0,1,283.74,186.027Zm4.6-10.2-4.6,7.216-4.6-7.216a5.529,5.529,0,1,1,9.2,0Z"
                  transform="translate(-259.045 -155.802)"
                  fill="#000"
                ></path>
                <path
                  d="M355.072,226a3.072,3.072,0,1,0,3.072,3.072A3.075,3.075,0,0,0,355.072,226Zm0,4.915a1.843,1.843,0,1,1,1.843-1.843A1.845,1.845,0,0,1,355.072,230.915Z"
                  transform="translate(-330.376 -212.116)"
                  fill="#000"
                ></path>
                <ellipse
                  cx="1"
                  cy="0.5"
                  rx="1"
                  ry="0.5"
                  transform="translate(13.727 28.727)"
                ></ellipse>
              </svg>
              <p>
                Kordonboyu Mahallesi, Yunus Caddesi No:3
                <span>Kartal/İstanbul</span>
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactArea;
