import React from "react";
import Header from "../components/MainPage/Header";
import Slider from "../components/MainPage/Slider";
import ContactArea from "../components/ContactPage";

const Contact = () => {
  return (
    <div>
      <Header />
      <Slider isContactPage />
      <ContactArea />
    </div>
  );
};

export default Contact;
