import React from "react";
import { Link, useParams } from "react-router-dom";
import { ProductData } from "../../../utils";
import "./style.scss";

const ProductPage = () => {
  const { id } = useParams();
  const product = ProductData.find((item) => item.id === id);
  const isMobile = window.innerWidth <= 768;

  if (!product) {
    return <div>Ürün bulunamadı.</div>;
  }

  return (
    <div className="product-page-info">
      <div className={`products-header ${isMobile ? "mobile" : ""}`}>
        <div className="shadow"></div>
        <div className="ellipsis">
          <img src={`${process.env.PUBLIC_URL}/images/canva.png`} alt="" />
        </div>
      </div>
      <div className="product-page">
        <div className="breadcrumb">
          <Link to="/">Anasayfa</Link> &gt; <Link to="/products">Ürünler</Link>{" "}
          &gt; <span>{product.name}</span>
        </div>
        <header className="product-header">
          <h1>Ürünlerimiz</h1>
        </header>
        <div className="product-categories">
          {product.products.map((item) => (
            <div key={item.id} className="category">
              <img
                src={`${process.env.PUBLIC_URL}/images/${item.image}`}
                alt=""
              />
              <h2>{item.name}</h2>
            </div>
          ))}
        </div>
      </div>
      <div className="product-detail">
        {/* <h1>{product.name}</h1> */}
        {/* <img
          src={`${process.env.PUBLIC_URL}/images/${product.image}`}
          alt={product.name}
        /> */}
        {/* <p>{product.description}</p> */}
      </div>
    </div>
  );
};

export default ProductPage;
