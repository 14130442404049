import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    const header = document.querySelector(".header");
    const headerHeight = header?.offsetHeight;
    if (window.scrollY > headerHeight) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const goHome = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isMobileMenuOpen]);

  const handleNavigateToInfo = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (location.pathname === "/") {
      window.scrollTo({
        top: !isMobile ? 630 : 350,
        behavior: "smooth",
      });
    } else {
      navigate("/", { state: { scrollToInfo: true } });
    }
  };
  return !isMobile ? (
    <>
      <div className={`header ${isSticky ? "stickyHeader" : ""}`}>
        <Link to="/" className="logo">
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
          Beta Robotik
        </Link>
        <div className="navbar">
          <div className="navbar-item">
            <Link onClick={goHome}>ANA SAYFA</Link>
          </div>
          <div className="navbar-item">
            <button onClick={handleNavigateToInfo}>HAKKIMIZDA</button>
          </div>
          <div className="navbar-item">
            <Link
              to="/products"
              onClick={(e) => {
                if (location.pathname === "/products") {
                  e.preventDefault();
                }
                goTop();
              }}
            >
              ÜRÜNLER
            </Link>
          </div>
          <div className="navbar-item">
            <Link
              onClick={(e) => {
                goTop();
              }}
              to="/contact"
            >
              İLETİŞİM
            </Link>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={`header ${isMobile ? "mobile" : ""}`}>
        {isMobile && (
          <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
            {!isMobileMenuOpen ? <i className="fa-solid fa-bars"></i> : ""}
          </div>
        )}

        <div
          className={`mobile-menu ${
            isMobile && isMobileMenuOpen ? "open" : ""
          }`}
        >
          <div className="navbar-item">
            <Link to="/" className="logo">
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
              Beta Robotik
            </Link>
          </div>
          <div className="navbar-item">
            <button onClick={goHome}>ANA SAYFA</button>
          </div>
          <div className="navbar-item">
            <button onClick={handleNavigateToInfo}>HAKKIMIZDA</button>
          </div>
          <div className="navbar-item">
            <Link to="/products">ÜRÜNLER</Link>
          </div>
          <div className="navbar-item">
            <Link to="/contact">İLETİŞİM</Link>
          </div>
        </div>
        {isMobile && isMobileMenuOpen && (
          <div className="closeTab" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <i onClick={toggleMobileMenu} className="fa-solid fa-bars"></i>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
