import React from "react";
import "./style.scss";

const Info = () => {
  return (
    <div className="business">
      <div className="who-we-are">
        <div className="left-area">
          <span>Kurumsal</span>
          <b>Hakkımızda</b>
          <p>
            Firmamız, güç aktarım sistemleri ve lineer otomasyon alanında
            ihtiyaç duyulan parçaları müşterilerimize en hızlı ve verimli
            şekilde sunan bir çözüm ortağıdır. Sektördeki deneyimimiz ve geniş
            ağımız sayesinde, en kaliteli ve güvenilir ürünleri temin ederek,
            müşterilerimizin operasyonel süreçlerini kesintisiz sürdürmelerini
            sağlıyoruz. İş ortaklarımızla kurduğumuz güçlü ilişkiler ve piyasa
            bilgimizle, her türlü gereksinime uygun çözümler geliştiriyor,
            müşterilerimizin taleplerine hızlı yanıt veriyoruz.
            <br />
            <br />
            Amacımız, yalnızca parça temin etmek değil, müşterilerimizin
            ihtiyaçlarını doğru analiz ederek onlara değer katan çözümler
            sunmaktır. Güç aktarım ve lineer otomasyon sistemlerinde en güncel
            ürünleri, en uygun koşullarda sağlayarak, sektördeki rekabet
            güçlerini artırmalarına katkıda bulunuyoruz. Profesyonel ekibimizle,
            her adımda destek sunarak, uzun vadeli iş birliğine dayalı güvenilir
            hizmet vermeye odaklanıyoruz.
          </p>
        </div>
        <div className="right-area">
          <img src={`${process.env.PUBLIC_URL}/images/business.jpg`} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Info;
