import React, { useEffect } from "react";
import Header from "../components/MainPage/Header";
import Slider from "../components/MainPage/Slider";
import ProductSlider from "../components/MainPage/ProductSlider";
import Info from "../components/MainPage/Info";
import { useLocation, useNavigate } from "react-router-dom";
import Partners from "../components/MainPage/Partners";

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (location.state?.scrollToInfo) {
      window.scrollTo({
        top: !isMobile ? 630 : 350,
        behavior: "smooth",
      });

      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  return (
    <div>
      <Header />
      <Slider />
      <Info />
      <ProductSlider />
      <Partners />
    </div>
  );
};

export default MainPage;
