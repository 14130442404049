import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/MainPage/Header";
import ProductList from "../components/Products/ProductList";
import ProductPage from "../components/Products/ProductPage";

const Products = () => {
  const location = useLocation();
  return (
    <div>
      <Header />
      {location.pathname === "/products" && <ProductList />}
      {location.pathname.startsWith("/products/") && <ProductPage />}
    </div>
  );
};

export default Products;
