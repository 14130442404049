import React from "react";
import "./style.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-area area-1">
        <h2>Bize Ulaşın</h2>
        <span>
          {/* <b>B</b> */}
          <i className="fa-solid fa-phone"></i>
          <a href="tel:+90543 270 24 66">+90(543) 270 24 66</a>
        </span>
        <span>
          {/* <b>E</b> */}
          <i className="fa-solid fa-phone"></i>
          <a href="tel:+90535 357 18 97">+90(535) 357 18 97</a>
        </span>
        <span>
          <i className="fa-solid fa-envelope"></i>
          <a href="mail:info@betarobotik.com">info@betarobotik.com</a>
        </span>
      </div>
      <div className="footer-area area-2">
        <h2>Ofis Adres</h2>
        <img src={`${process.env.PUBLIC_URL}/images/map-icon.svg`} alt="" />
        <p>
          Emek Mahallesi, Ordu Caddesi, Sinpaş Aqua City No:39
          <span>
            <br />
            Sancaktepe/İstanbul
          </span>
        </p>
      </div>
      <div className="footer-area area-3">
        <h2>Depo Adres</h2>
        <img src={`${process.env.PUBLIC_URL}/images/map-icon.svg`} alt="" />
        <p>
          Kordonboyu Mahallesi, Yunus Caddesi No:3
          <span>
            <br />
            Kartal/İstanbul
          </span>
        </p>
      </div>
      <div className="footer-area area-4"></div>
    </div>
  );
};

export default Footer;
