import React, { useRef, useState, useEffect } from "react";
import "./style.scss";
import { PartnersLogo } from "../../../utils";

const Partners = () => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [previousScrollLeft, setPreviousScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = (e) => {
    if (!isDragging) return;
    setIsDragging(false);

    if (scrollContainerRef.current.scrollLeft % 284 > 10) {
      const currentScrollLeft = scrollContainerRef.current.scrollLeft;
      const scrollDirection =
        currentScrollLeft > previousScrollLeft ? "right" : "left";
      const offset = currentScrollLeft % 284;
      let scrollAmount;

      if (scrollDirection === "right") {
        scrollAmount = 284 - offset;
      } else {
        scrollAmount = -offset;
      }

      if (offset !== 0) {
        scrollContainerRef.current.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
      setPreviousScrollLeft(currentScrollLeft);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const iconScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -284,
        behavior: "smooth",
      });
    }
  };

  const iconScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 284,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleWindowMouseMove = (e) => {
      if (!isDragging) return;
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - startX) * 1;
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleWindowMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener("mousemove", handleWindowMouseMove);
    window.addEventListener("mouseup", handleWindowMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
      window.removeEventListener("mouseup", handleWindowMouseUp);
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div className="partners">
      <div className="partners-area">
        <div className="partners-header">
          <i className="fa-solid fa-arrow-left" onClick={iconScrollLeft}></i>
          <b>MARKALARIMIZ</b>
          <i className="fa-solid fa-arrow-right" onClick={iconScrollRight}></i>
        </div>
        <div
          className="cart-area"
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {PartnersLogo.map((item, key) => (
            <div key={key} className="cart">
              <img
                src={`${process.env.PUBLIC_URL}/images/${item.image}`}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
