import React, { useRef, useState, useEffect } from "react";
import { ProductData } from "../../../utils";
import "./style.scss";

const ProductSlider = () => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [notDraggableClick, setNotDraggableClick] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [activeItem, setActiveItem] = useState(ProductData[0]);
  const [previousScrollLeft, setPreviousScrollLeft] = useState(0);

  const handleClick = (item) => {
    if (!notDraggableClick) {
      setActiveItem(item);
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = (e) => {
    if (!isDragging) return;
    setIsDragging(false);

    if (scrollContainerRef.current.scrollLeft % 200 > 10) {
      const currentScrollLeft = scrollContainerRef.current.scrollLeft;
      const scrollDirection =
        currentScrollLeft > previousScrollLeft ? "right" : "left";
      const offset = currentScrollLeft % 200;
      let scrollAmount;

      if (scrollDirection === "right") {
        scrollAmount = 200 - offset;
      } else {
        scrollAmount = -offset;
      }

      if (offset !== 0) {
        scrollContainerRef.current.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
      setPreviousScrollLeft(currentScrollLeft);
    }

    setTimeout(() => {
      setNotDraggableClick(false);
    }, 100);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    setTimeout(() => {
      setNotDraggableClick(true);
    }, 100);
  };

  const iconScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const iconScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleWindowMouseMove = (e) => {
      if (!isDragging) return;
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - startX) * 1;
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleWindowMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener("mousemove", handleWindowMouseMove);
    window.addEventListener("mouseup", handleWindowMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
      window.removeEventListener("mouseup", handleWindowMouseUp);
    };
  }, [isDragging, startX, scrollLeft]);

  useEffect(() => {
    const activeElement =
      scrollContainerRef.current.querySelector(".product.active");

    if (activeElement) {
      const containerLeft =
        scrollContainerRef.current.getBoundingClientRect().left;
      const elementLeft = activeElement.getBoundingClientRect().left;
      const scrollAmount = elementLeft - containerLeft;
      if (elementLeft < containerLeft) {
        scrollContainerRef.current.scrollLeft += scrollAmount;
      } else if (
        elementLeft + activeElement.offsetWidth >
        containerLeft + scrollContainerRef.current.offsetWidth
      ) {
        scrollContainerRef.current.scrollLeft += scrollAmount;
      }
    }
  }, [activeItem]);

  return (
    <div className="product-slider">
      <div className="product-area">
        <div className="product-image">
          <img
            src={`${process.env.PUBLIC_URL}/images/${activeItem.image}`}
            alt=""
          />
        </div>
        <div className="products">
          <div className="slider-header">
            <i className="fa-solid fa-arrow-left" onClick={iconScrollLeft}></i>
            <div className="product-name">
              <b>ÜRÜNLER</b>
            </div>
            <i
              className="fa-solid fa-arrow-right"
              onClick={iconScrollRight}
            ></i>
          </div>
          <div
            className="all-products"
            ref={scrollContainerRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {ProductData.map((item) => (
              <div
                key={item.id}
                className={`product ${item === activeItem ? "active" : ""}`}
                onClick={() => handleClick(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
          <p className="product-context">{activeItem.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductSlider;
