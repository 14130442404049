import React from "react";
import { ProductData } from "../../../utils";
import { Link } from "react-router-dom";
import "./style.scss";

const ProductList = () => {
  const isMobile = window.innerWidth <= 768;
  return (
    <>
      <div className={`products-header ${isMobile ? "mobile" : ""}`}>
        <div className="shadow"></div>
        <div className="ellipsis">
          <img src={`${process.env.PUBLIC_URL}/images/canva.png`} alt="" />
        </div>
      </div>
      <div className="product-page">
        <div className="breadcrumb">
          <Link to="/">Anasayfa</Link> &gt; <span>Ürünler</span>
        </div>
        <header className="product-header">
          <h1>Ürünlerimiz</h1>
        </header>
        <div className="product-categories">
          {ProductData.map((item) => (
            <div key={item.id} className="category">
              <Link
                to={`/products/${item.id}`}
                onClick={(e) => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/${item.image}`}
                  alt=""
                />
                <h2>{item.name}</h2>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductList;
