export const ProductData = [
  {
    id: "reducer",
    name: "Redüktörler",
    description:
      "Redüktör, bir dönme hareketinin devir-tork oranını değiştiren dişli sistemidir. Vites kutularıyla birlikte dişli çark düzeneklerinin paralel dişli dizilerinin bir elemanıdır. Yapısal bakımdan redüktörler, gövde içine yerleştirilmiş dişli çarklar, miller, yataklar gibi parçalardan oluşan sistemlerdir.",
    image: "nygard-red.png",
    products: [
      {
        id: "planet-reducer",
        name: "Planet Redüktörler",
        description: "",
        image: "nygard-red.png",
      },
      {
        id: "robotic-reducer",
        name: "Robotik Redüktörler",
        description: "",
        image: "robotic-reducer.png",
      },
      {
        id: "direction-changer-reducer",
        name: "Yön Değiştiren Redüktörler",
        description: "",
        image: "yon-degistiren-red.png",
      },
      {
        id: "helisel-conical-reducer",
        name: "Helisel, Konik ve Sonsuz Redüktörler",
        description: "",
        image: "helisel-red.png",
      },
    ],
  },
  {
    id: "racs-pinions",
    name: "Kremayer Ve Pinyonlar",
    description:
      "Kremayer ve Pinyonlar, mekanik sistemlerde kullanılan dişli türleridir. Kremayer, düz bir dişli çubuktur ve genellikle dairesel hareketi lineer harekete dönüştürmek için kullanılır. Pinyon ise, dişli çarktır ve kremayerle etkileşimde bulunarak lineer hareket sağlar. Bu iki bileşen, otomasyon sistemleri, araç direksiyon sistemleri ve çeşitli makine tasarımlarında yaygın olarak kullanılır. Kremayer ve pinyon kombinasyonu, güçlü ve verimli bir hareket aktarım sistemi sağlar.",
    image: "racs.png",
    products: [
      {
        id: "helis-racs",
        name: "Helis Dişli Kremayer Ve Pinyonlar",
        description: "",
        image: "helisel-kre.png",
      },
      {
        id: "straight-racs",
        name: "Düz Dişli Kremayer Ve Pinyonlar",
        description: "",
        image: "straight-kre.png",
      },
    ],
  },
  {
    id: "couplings",
    name: "Kaplinler",
    description:
      "Kaplinler, mekanik sistemlerde iki parçayı birbirine bağlamak ve hareketi iletmek için kullanılan elemanlardır. Genellikle motor, şaft veya dişli çarklar gibi hareket eden parçaları birleştirmek amacıyla tasarlanırlar. Kaplinler, sürtünme, esneklik veya dişli sistemleri gibi çeşitli yöntemlerle çalışabilir. Çeşitli kaplin türleri, titreşim emme, yanlış hizalamayı telafi etme ve kuvvet iletiminde kayıpları azaltma gibi işlevler sunar. Kaplinler, otomotiv, endüstriyel makineler ve enerji üretim sistemlerinde yaygın olarak kullanılır.",
    image: "kaplin.png",
    products: [
      {
        id: "couplings-tork-limitors",
        name: "Kaplinler Ve Tork Limitörleri",
        description: "",
        image: "kaplin.png",
      },
    ],
  },
  {
    id: "ball-screws-and-support-units",
    name: "Vidalı Miller Ve Uç Yatakları",
    description:
      "Vidalı Miller, genellikle lineer hareket elde etmek için kullanılan bir mekanizma olup, dişli çark sistemleri ile çalışan bir sistemdir. Bu mekanizmanın temel bileşeni, bir vida ve ona bağlı bir somun ile oluşturulan düzlem hareketidir. Vidalı miller, özellikle otomasyon sistemlerinde, CNC makinelerinde ve çeşitli taşıma sistemlerinde hassas hareket kontrolü sağlamak amacıyla kullanılır. Vidalı millerler, yüksek yük taşıma kapasitesine ve uzun ömürlülüğe sahip olmasıyla bilinir. Uç Yatakları, genellikle bir millerin uç noktalarında yer alan yatak elemanlarıdır. Bu yataklar, millerin dönme hareketi sırasında stabilite ve destek sağlamak amacıyla tasarlanmıştır. Uç yatakları, aşınmayı azaltmak ve hareketin düzgünlüğünü artırmak için çeşitli malzemelerden üretilir. Uç yatakları, makinelerin verimliliğini artırırken, aynı zamanda sistemin genel dayanıklılığını da olumlu yönde etkiler. Özellikle otomotiv, sanayi makineleri ve robot teknolojilerinde sıkça kullanılır.",
    image: "vidali-mil.png",
    products: [
      {
        id: "ball-screws-and-support-units",
        name: "Vidalı Miller Ve Uç Yatakları",
        description: "",
        image: "vidali-mil.png",
      },
    ],
  },
  {
    id: "linear-rail-and-guides",
    name: "Lineer Ray Ve Arabalar",
    description:
      "Lineer Ray ve Arabalar, hareket sistemlerinde sıklıkla kullanılan bileşenlerdir. Lineer ray, düz bir yüzey üzerinde hareket eden nesnelerin yönlendirilmesi ve desteklenmesi amacıyla tasarlanmış bir yapıdadır. Arabalar ise, bu raylar üzerinde kayarak hareket eden elemanlardır. Lineer ray ve arabalar kombinasyonu, otomasyon sistemlerinde, endüstriyel makinelerde ve robotik uygulamalarda hassas ve güvenilir hareket kontrolü sağlar. Bu sistemler, yük taşıma kapasitesi yüksek ve sürtünmeyi minimize eden yapıları sayesinde verimliliği artırır.",
    image: "schaeffler.png",
    products: [
      {
        id: "ina-linear-rail-and-guides",
        name: "INA Lineer Ray Ve Arabalar",
        description: "",
        image: "ina-lineer.png",
      },
      {
        id: "hiwin-linear-rail-and-guides",
        name: "Hiwin Lineer Ray Ve Arabalar",
        description: "",
        image: "hiwin-lineer.png",
      },
    ],
  },
  {
    id: "linear-modules",
    name: "Lineer Modüller ve Aktuatorler",
    description:
      "Lineer Modüller ve Aktüatörler, otomasyon ve endüstriyel uygulamalarda sıkça kullanılan mekanik sistem bileşenleridir. Lineer modüller, belirli bir doğrusal hareketi sağlamak amacıyla tasarlanmış yapılar olup, genellikle ray ve araba sistemleri ile bir arada çalışır. Aktüatörler ise, elektrik, pnömatik veya hidrolik enerji ile hareket üreten elemanlardır. Bu iki bileşen, hassas hareket kontrolü, yük taşıma ve otomasyon sistemlerinin verimliliğini artırmak için kullanılır. Lineer modüller ve aktüatörlerin kombinasyonu, yüksek performanslı ve güvenilir hareket sistemleri oluşturarak endüstriyel süreçlerin optimizasyonuna katkıda bulunur.",
    image: "kartezyen.png",
    products: [
      {
        id: "ball-screw-driven-linear-units",
        name: "Vidalı Milli Lineer Modüller",
        description: "",
        image: "vidali-milli.png",
      },
      {
        id: "belt-driven-linear-units",
        name: "Kayışlı Lineer Modüller",
        description: "",
        image: "kayisli-lineer.png",
      },
      {
        id: "rack-driven-linear-units",
        name: "Kremayerli Lineer Modüller",
        description: "",
        image: "kremayerli-lineer.png",
      },
      {
        id: "cartesian-untis",
        name: "Kartezyen Sistemler",
        description: "",
        image: "kartezyen.png",
      },
      {
        id: "linear-electric-actuators",
        name: "Elektrikli Aktuatorler",
        description: "",
        image: "elektrikli-aktuatorler.png",
      },
    ],
  },
  {
    id: "sleving-bearings",
    name: "Döner Tabla Rulmanları",
    description:
      "Döner Tabla Rulmanları, döner hareketlerin sağlanması için özel olarak tasarlanmış mekanik bileşenlerdir. Bu rulmanlar, döner tabla veya benzeri yapıların eksen etrafında düzgün ve stabil bir şekilde dönmesini sağlamak amacıyla kullanılır. Genellikle ağır yük taşıma kapasitesine sahip olan döner tabla rulmanları, yüksek hassasiyet ve dayanıklılık sunar. Otomasyon sistemleri, makine mühendisliği ve çeşitli endüstriyel uygulamalarda yaygın olarak tercih edilir. Bu rulmanlar, hareketin akıcılığını artırırken, aşınmayı azaltarak sistemin ömrünü uzatır.",
    image: "donen-rulman.png",
    products: [
      {
        id: "privatesleving-bearings",
        name: "Özel Döner Tabla Rulmanları",
        description: "",
        image: "donen-rulman.png",
      },
    ],
  },
  {
    id: "gear-manufacturing",
    name: "Özel Dişli İmalatı",
    description:
      "Özel Dişli İmalatı, belirli uygulama ihtiyaçlarına göre tasarlanmış ve üretilmiş dişli elemanlarını ifade eder. Bu dişliler, standart dişli boyut ve şekillerinin dışında, müşteri taleplerine ve mühendislik gereksinimlerine uygun şekilde özel olarak üretilebilir. Özel dişli imalatı, endüstriyel makineler, otomotiv sistemleri ve robot teknolojileri gibi birçok alanda kritik öneme sahiptir. Bu süreç, yüksek hassasiyet ve dayanıklılık sunan dişli çözümleri sağlayarak, sistemlerin verimliliğini artırır ve performansını optimize eder.",
    image: "ozel-disli.png",
    products: [
      {
        id: "private-gear-manufacturing",
        name: "Özel Dişli İmalatı",
        description: "",
        image: "ozel-disli.png",
      },
    ],
  },
];

export const PartnersLogo = [
  {
    id: 1,
    name: "Neugart",
    image: "neugart.png",
  },
  {
    id: 2,
    name: "Kofon",
    image: "kofon.png",
  },
  // {
  //   id: 3,
  //   name: "Elt",
  //   image: "",
  // },
  {
    id: 4,
    name: "Schaeffler Ultra Precision Drives",
    image: "schaeffler-logo.png",
  },
  {
    id: 5,
    name: "Melior Motion",
    image: "melior-motion.png",
  },
  // {
  //   id: 6,
  //   name: "Dz",
  //   image: "",
  // },
  {
    id: 7,
    name: "Tramec",
    image: "tramec.png",
  },
  {
    id: 8,
    name: "INA",
    image: "ina.png",
  },
  {
    id: 9,
    name: "HIWIN",
    image: "hiwin.png",
  },
  // {
  //   id: 10,
  //   name: "Bosch",
  //   image: "bosch.png",
  // },
  {
    id: 11,
    name: "Rexroth",
    image: "rexroth.png",
  },
  {
    id: 12,
    name: "Mayr",
    image: "mayr.png",
  },
  {
    id: 13,
    name: "Gten",
    image: "gten.png",
  },
];
