import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { Link } from "react-router-dom";

const Slider = ({ isContactPage = false }) => {
  return (
    <div className={`slider ${isContactPage && "contactPageSlider"}`}>
      <div className="shadow"></div>
      {!isContactPage ? (
        <div className="banner">
          <div className="banner-header">SOLUTION CENTER!</div>
          <div className="banner-text">
            Alanında uzman ve tecrübeli kadromuzla, ihtiyaçlarınıza en uygun
            ürünleri belirlemeniz için ücretsiz mühendislik danışmanlık hizmeti
            sunuyoruz.
          </div>
          <Link to="/products" className="banner-button">
            Ürünlerimizi İnceleyin
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

Slider.propTypes = {
  isContactPage: PropTypes.bool,
};

export default Slider;
